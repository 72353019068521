import React from "react";
import moon from "./moon.png"; // Import the new image
import cat from "./cat.png"; // Import the new image
import ParticlesStars from "./ParticlesStars";
import { DvdScreensaver } from "react-dvd-screensaver";
import { TypeAnimation } from "react-type-animation";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import "./App.css";

const Home = () => {
  return (
    <>
        <div className="component-parent">
          <DvdScreensaver>
            <img src={cat} alt="floating cat" />
          </DvdScreensaver>
        </div>
        <div style={styles.container} className="App">
          <ParticlesStars />
          <div className="moon1"></div>
          <header style={styles.header}>
            <h1 style={styles.title}>FULLMOON</h1>
            <p style={styles.date}>AUGUST 21</p>
            <TypeAnimation
              sequence={[
                "MAKE MOONING GREAT AGAIN!",
                1000,
                "MEEEOOOOWWWWWW",
                1000,
                "MOON DON'T DEV",
                1000,
                "MEEEOOOOWWWWWW",
                1000,
                "COMMUNITY DEV",
                1000,
                "MEEEOOOOWWWWWW",
                1000,
              ]}
              wrapper="span"
              speed={50}
              style={styles.tagline}
              repeat={Infinity}
            />
          </header>

          <div style={styles.socialLinks}>
            <a
              href="https://t.me/happymealonbase"
              target="_blank"
              rel="noopener noreferrer"
              style={styles.socialButton}
              className="socialButton"
            >
              <i className="fab fa-telegram-plane"></i> Telegram
            </a>
            <a
              href="https://x.com/HappyMealOnBase"
              target="_blank"
              rel="noopener noreferrer"
              style={styles.socialButton}
              className="socialButton"
            >
              <i className="fab fa-twitter"></i> Twitter
            </a>
            <Link to="/memes" style={styles.socialButton} className="socialButton">
              MEMES
            </Link>
          </div>
        </div>
        </>
  );
};

const styles = {
  container: {
    textAlign: "center",
    backgroundColor: "#282c34",
    color: "white",
    padding: "20px",
    position: "relative",
    height: "100vh",
    width: "100vw",
    overflow: "scroll",
    paddingBottom: 20
  },
  header: {
    position: "relative",
    marginTop: "500px",
    zIndex: 30,
    color: "#fff",
  },
  title: {
    fontSize: "6em",
    margin: "0",
    color: "#FFFFFF",
    textShadow:
      "0 0 5px #FFFFFF, 0 0 10px #FFFFFF, 0 0 20px #FF00FF, 0 0 30px #FF00FF, 0 0 40px #FF00FF, 0 0 55px #FF00FF, 0 0 75px #FF00FF",
  },
  date: {
    fontSize: "6em",
    margin: "0",
    color: "#FFFFFF",
    textShadow:
      "0 0 5px #FFFFFF, 0 0 10px #FFFFFF, 0 0 20px #00FFFF, 0 0 30px #00FFFF, 0 0 40px #00FFFF, 0 0 55px #00FFFF, 0 0 75px #00FFFF",
  },
  tagline: {
    fontSize: "3em",
    margin: "0",
    color: "#FFFFFF",
    textShadow:
      "0 0 5px #FFFFFF, 0 0 10px #FFFFFF, 0 0 20px #FFD700, 0 0 30px #FFD700, 0 0 40px #FFD700, 0 0 55px #FFD700, 0 0 75px #FFD700",
  },
  socialLinks: {
    position: "relative",
    marginTop: "40px",
    display: "flex",
    justifyContent: "center",
    zIndex: 20,
    marginBottom: '80px'
  },
  socialButton: {
    color: "white",
    margin: "20px 20px",
    textDecoration: "none",
    fontSize: "1.5em",
    transition: "transform 0.2s, opacity 0.2s",
    display: "inline-block",
  },
  "@keyframes scaleOpacity": {
    "0%": { transform: "scale(1)", opacity: 1 },
    "50%": { transform: "scale(1.2)", opacity: 0.7 },
    "100%": { transform: "scale(1)", opacity: 1 },
  },
  socialButtonHover: {
    animation: "scaleOpacity 1s infinite",
  },
};

export default Home;
