import React from "react";
import moon from "./moon.png"; // Import the new image
import cat from "./cat.png"; // Import the new image
import ParticlesStars from "./ParticlesStars";
import { DvdScreensaver } from "react-dvd-screensaver";
import { TypeAnimation } from "react-type-animation";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import meme1 from "./meme1.png";
import meme2 from "./meme2.png";
import meme3 from "./meme3.png";
import meme4 from "./meme4.png";
import meme5 from "./meme5.png";
import meme6 from "./meme6.png";
import meme7 from "./meme7.png";
import meme8 from "./meme8.png";
import meme9 from "./meme9.png";
import meme10 from "./meme10.png";
import meme11 from "./meme11.png";
import meme12 from "./meme12.png";
import meme13 from "./meme13.png";
import meme14 from "./meme14.png";
import meme15 from "./meme15.png";
import meme16 from "./meme16.png";
import FullMoonBanner from "./FullMoonBanner.png";

import "./App.css";

const images = [
  meme1,
  meme2,
  meme3,
  meme4,
  meme5,
  meme6,
  meme7,
  meme8,
  meme9,
  meme10,
  meme11,
  meme12,
  meme13,
  meme14,
  meme15,
  meme16,
];

const Memes = () => {

  return (
    <>
      <div style={styles.container} className="App">
        <ParticlesStars />
        {/* <div className="moon1"></div> */}
        <img src={FullMoonBanner} alt="banner" style={{width: '100%', maxWidth: '1000px', zIndex: 10, position: 'relative', borderRadius: 40}} />
          <header style={styles.header}>
            {/* <h1 style={styles.title}>FULLMOON</h1> */}
            {/* <p style={styles.date}>JULY 21</p> */}
            <TypeAnimation
              sequence={[
                "RAID WITH US!",
                1000,
                "MEEEOOOOWWWWWW",
                1000,
              ]}
              wrapper="span"
              speed={50}
              style={styles.tagline}
              repeat={Infinity}
            />
          </header>
        <div className="memes-page">
          <h1 className="heading">Memes</h1>
          <div className="memes-grid">
          {images.map((image, index) => (
            <div key={index} className="photo-grid-item">
              <LazyLoadImage
                src={image}
                alt={`Gallery ${index + 1}`}
                effect="blur" // You can choose other effects like 'opacity' or 'black-and-white'
              />
            </div>
          ))}
          </div>
        </div>

        <header style={styles.header}>
            {/* <h1 style={styles.title}>FULLMOON</h1> */}
            {/* <p style={styles.date}>JULY 21</p> */}
            <TypeAnimation
              sequence={[
                "SEND US YOUR BEST MOON POSE",
                1000,
                "MEEEOOOOWWWWWW",
                1000,
              ]}
              wrapper="span"
              speed={50}
              style={styles.tagline}
              repeat={Infinity}
            />
          </header>

        <div style={styles.socialLinks}>
          <Link to="/" style={styles.socialButton} className="socialButton">
            Home
          </Link>
        </div>
      </div>
    </>
  );
};

const styles = {
  container: {
    textAlign: "center",
    backgroundColor: "#282c34",
    color: "white",
    padding: "20px",
    position: "relative",
    // height: "100vh",
    // width: "100vw",
    overflow: "scroll",
    paddingBottom: 20
  },
  header: {
    position: "relative",
    marginTop: "100px",
    zIndex: 30,
    color: "#fff",
  },
  title: {
    fontSize: "6em",
    margin: "0",
    color: "#FFFFFF",
    textShadow:
      "0 0 5px #FFFFFF, 0 0 10px #FFFFFF, 0 0 20px #FF00FF, 0 0 30px #FF00FF, 0 0 40px #FF00FF, 0 0 55px #FF00FF, 0 0 75px #FF00FF",
  },
  date: {
    fontSize: "6em",
    margin: "0",
    color: "#FFFFFF",
    textShadow:
      "0 0 5px #FFFFFF, 0 0 10px #FFFFFF, 0 0 20px #00FFFF, 0 0 30px #00FFFF, 0 0 40px #00FFFF, 0 0 55px #00FFFF, 0 0 75px #00FFFF",
  },
  tagline: {
    fontSize: "3em",
    margin: "0",
    color: "#FFFFFF",
    textShadow:
      "0 0 5px #FFFFFF, 0 0 10px #FFFFFF, 0 0 20px #FFD700, 0 0 30px #FFD700, 0 0 40px #FFD700, 0 0 55px #FFD700, 0 0 75px #FFD700",
  },
  socialLinks: {
    position: "relative",
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
    marginBottom: '80px'
  },
  socialButton: {
    color: "white",
    margin: "20px 20px",
    textDecoration: "none",
    fontSize: "1.5em",
    transition: "transform 0.2s, opacity 0.2s",
    display: "inline-block",
  },
  "@keyframes scaleOpacity": {
    "0%": { transform: "scale(1)", opacity: 1 },
    "50%": { transform: "scale(1.2)", opacity: 0.7 },
    "100%": { transform: "scale(1)", opacity: 1 },
  },
};

export default Memes;
